export { default as image1 } from "./img-1.jpg";
export { default as image2 } from "./img-2.png";
export { default as image4 } from "./img-4.png";
export { default as image5 } from "./img-5.png";
export { default as image6 } from "./img-6.png";
export { default as image7 } from "./img-7.png";
export { default as image8 } from "./img-8.png";
export { default as image9 } from "./img-9.png";
export { default as video } from "./video.png";

export { default as image10 } from "./img-10.png";
export { default as image11 } from "./img-11.png";
export { default as image12 } from "./img-12.png";
export { default as image13 } from "./img-13.png";
export { default as image14 } from "./img-14.png";
export { default as image15 } from "./img-15.png";
export { default as image16 } from "./img-16.png";

export { default as image17 } from "./img-17.png";
export { default as image18 } from "./img-18.png";
export { default as image19 } from "./img-19.png";
export { default as image20 } from "./img-20.png";

export { default as FooterLogo } from "./footer-logo.png";
export { default as Logo } from "./Logo.png";
export { default as header1 } from "./h-icon-1.png";
export { default as header2 } from "./h-icon-2.png";
export { default as notFoundImage } from './notFound.png';

export { default as image25 } from "./img-25.png";
export { default as image26 } from "./img-26.png";
export { default as image27 } from "./img-27.png";
export { default as image28 } from "./img-28.png";
export { default as image29 } from "./img-29.png";
export { default as image30 } from "./img-30.png";
export { default as image31 } from "./img-31.png";
export { default as image32 } from "./img-32.png";
export { default as comingsoon } from "./comingsoon.png";


export { default as AllProducts } from "./user_experience_images/All Products.jpg";
export { default as FitnessBundle10 } from "./user_experience_images/Fitness Bundle - 10.jpg";
export { default as FitnessBundle18 } from "./user_experience_images/Fitness Bundle - 18.jpg";
export { default as FitnessBundle61 } from "./user_experience_images/Fitness Bundle - 61.jpg";
export { default as FitnessBundle62 } from "./user_experience_images/Fitness Bundle - 62.jpg";
export { default as FitnessBundle63 } from "./user_experience_images/Fitness Bundle - 63.jpg";
export { default as FitnessBundle64 } from "./user_experience_images/Fitness Bundle - 64.jpg";
export { default as FitnessBundle65 } from "./user_experience_images/Fitness Bundle - 65.jpg";
export { default as FitnessBundle66 } from "./user_experience_images/Fitness Bundle - 66.jpg";
export { default as FitnessBundle67 } from "./user_experience_images/Fitness Bundle - 67.jpg";
export { default as FitnessBundle68 } from "./user_experience_images/Fitness Bundle - 68.jpg";
export { default as FitnessBundle69 } from "./user_experience_images/Fitness Bundle - 69.jpg";
export { default as FitnessBundle70 } from "./user_experience_images/Fitness Bundle - 70.jpg";
export { default as FitnessBundle71 } from "./user_experience_images/Fitness Bundle - 71.jpg";
export { default as FitnessBundle72 } from "./user_experience_images/Fitness Bundle - 72.jpg";
export { default as FitnessBundle73 } from "./user_experience_images/Fitness Bundle - 73.jpg";
export { default as FitnessBundle74 } from "./user_experience_images/Fitness Bundle - 74.jpg";
export { default as FitnessBundle75 } from "./user_experience_images/Fitness Bundle - 75.jpg";
export { default as FitnessBundle76 } from "./user_experience_images/Fitness Bundle - 76.jpg";
export { default as LivcanaBalm5 } from "./user_experience_images/Livcana Balm - 5.jpg";
export { default as LivCanaEnergy1 } from "./user_experience_images/LivCana Energy - 1.jpg";
export { default as LivCanaEnergy4 } from "./user_experience_images/Livcana Energy - 4.jpg";
export { default as LivCanaEnergy5 } from "./user_experience_images/Livcana Energy - 5.jpg";
export { default as LivCanaEnergy8 } from "./user_experience_images/Livcana Energy - 8.jpg";
export { default as Livology1 } from "./user_experience_images/Livology - 1.jpg";
export { default as Livology2 } from "./user_experience_images/Livology - 2.jpg";
export { default as Livology3 } from "./user_experience_images/Livology - 3.jpg";
export { default as Livology4 } from "./user_experience_images/Livology - 4.jpg";
export { default as Livology7 } from "./user_experience_images/Livology - 7.jpg";
export { default as Livology8 } from "./user_experience_images/Livology - 8.jpg";
export { default as Livology9 } from "./user_experience_images/Livology - 9.jpg";
export { default as Livology11 } from "./user_experience_images/Livology - 11.jpg";
export { default as Livology12 } from "./user_experience_images/Livology - 12.jpg";
export { default as Livology13 } from "./user_experience_images/Livology - 13.jpg";
export { default as Livology14 } from "./user_experience_images/Livology - 14.jpg";
export { default as Livology15 } from "./user_experience_images/Livology - 15.jpg";
export { default as Livology17 } from "./user_experience_images/Livology - 17.jpg";
export { default as Livology19 } from "./user_experience_images/Livology - 19.jpg";
export { default as Livology20 } from "./user_experience_images/Livology - 20.jpg";
export { default as Livology22 } from "./user_experience_images/Livology - 22.jpg";
export { default as LivologyActivate10 } from "./user_experience_images/Livology Activate - 10.jpg";
export { default as LivologyMax1 } from "./user_experience_images/Livology Max - 1.jpg";
export { default as LivologyMax2 } from "./user_experience_images/Livology Max - 2.jpg";
export { default as LivologyMax3 } from "./user_experience_images/Livology Max - 6.jpg";
export { default as LivologySleep3 } from "./user_experience_images/Livology Sleep - 3.jpg";
export { default as LivologySleep9 } from "./user_experience_images/Livology Sleep - 9.jpg";
export { default as Morvida6 } from "./user_experience_images/Morvida - 6.jpg";


// Supplement packs images
export { default as LAB_SFP_LivcanaEnergy } from "./supplements_facts/LAB_SFP_Livcana-Energy.jpg";
export { default as LAB_SFP_LivologyActivate } from "./supplements_facts/LAB_SFP_Livology-Activate.jpg";
export { default as LAB_SFP_LivologyCleanse } from "./supplements_facts/LAB_SFP_Livology-Cleanse.jpg";
export { default as LAB_SFP_LivologyDetoxTea } from "./supplements_facts/LAB_SFP_Livology-Detox-Tea.jpg";
export { default as LAB_SFP_LivologySleep } from "./supplements_facts/LAB_SFP_Livology-Sleep.jpg";
export { default as LAB_SFP_MorvidaCollagenBoost } from "./supplements_facts/LAB_SFP_Morvida-Collagen-Boost.jpg";
export { default as LAB_SFP_Morvida } from "./supplements_facts/LAB_SFP_Morvida.jpg";


// Single products pack images
export { default as lemon } from "./Lemon.png";
export { default as acai } from "./Acai.png";
export { default as aloeVera } from "./AloeVera.png";
export { default as ashwaganda } from "./Ashwaganda.png";
export { default as bacillusCoagulans } from "./BacillusCoagulans.png";
export { default as blackFulvicPowder } from "./BlackFulvicPowder.png";
export { default as bovineCollagen } from "./BovineCollagen.png";
export { default as branchChainAminoAcids } from "./BranchChainAminoAcids.png";
export { default as caffine } from "./Caffine.png";
export { default as cbd } from "./CBD.png";
export { default as chagaMushroom } from "./ChagaMushroom.png";
export { default as chamomille } from "./Chamomille.png";
export { default as clarySage } from "./ClarySage.png";
export { default as coffeeCGarciniaCambogia } from "./CoffeeCGarciniaCambogia.png";
export { default as cordyceps } from "./Cordyceps.png";
export { default as dandelionroot } from "./Dandelionroot.png";
export { default as eucalyptus } from "./Eucalyptus.png";
export { default as gojiBerry } from "./GojiBerry.png";
export { default as grapefruit } from "./Grapefruit.png";
export { default as greenCoffeeBean } from "./GreenCoffeeBean.png";
export { default as greenTeaExtract } from "./GreenTeaExtract.png";
export { default as magnesium } from "./Magnesium.png";
export { default as maquiBerry } from "./MaquiBerry.png";
export { default as marshmelloRoot } from "./MarshmelloRoot.png";
export { default as melatonin } from "./Melatonin.png";
export { default as mtCoil } from "./MTCoil.png";
export { default as nonTHC } from "./Non-THC.png";
export { default as papaya } from "./Papaya.png";
export { default as peppermint } from "./Peppermint.png";
export { default as reishiMushroom } from "./ReishiMushroom.png";
export { default as resemary } from "./Resemary.png";
export { default as rhodiola } from "./Rhodiola.png";
export { default as sennaLeaf } from "./SennaLeaf.png";
export { default as turmeric } from "./Turmeric.png";
export { default as pdflogo } from "./LABLogo.jpg";
export { default as rosemaryOil } from './rosemaryOil.png';

export { default as Viagologo } from '../../../public/images/viagologo.png';

export { default as ViagoLogo2 } from "../../../public/images/ViagoLogo2.png"

// admin pannel


export { default as uploadFile } from "/public/images/uploadicon.png"
export { default as fileLogo } from "/public/images/filelogo.png"


